import React from 'react'



const Home = props => {
    return (
        <div className="home"  id = '1'>
            <div className = "home1"> 
                <div className = "home2">
                    <h1 className = 'navbarP2'>Alexander Nemechek</h1>
                    <p  className = 'welcomeP'>Welcome to my portfolio website</p>
                    <div className = "react1"></div>
                </div>
            </div>
                <div className = "home5">
            </div>
        </div>
    )
}

    export default Home